import React from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";

// styles for this website
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss?v=1.4.0";
import "./assets/demo/demo.css?v=1.4.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import './css/App.css';

// pages for this site
/*import Header from './components/Header/header';
import Footer from './components/Footer/Footer';
import Home from './views/Home/Home';
import Comments from './views/Comments/Comments';
import About from './views/About/About';

import Index from './views/Index.js';*/
import LoginPage from './views/examples/LoginPage.js';
import LandingPage from './views/examples/LandingPage.js';


function App() {
  
  return (    
    <div className="container-fluid m-0 p-0">
      <Router>
        <React.Fragment>
          <Switch>
          <Route path="/index" component={LandingPage} />            
            <Route
              path="/login-page"
              component={LoginPage}
            />
            <Redirect to="/index" />
            <Redirect from="/" to="/index" />
          </Switch>
        </React.Fragment>
      </Router>
    </div>
  );
}

export default App;
