import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "./../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "./../../components/Headers/LandingPageHeader.js";
import DefaultFooter from "./../../components/Footers/DefaultFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are?</h2>
                <h5 className="description">
                  A socially responsible company in gardening, 
                  maintenance and work in general, we are Miguel Lanscape your friends.                  
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("./../../assets/img/landscaping/lanscaping2.jpg") + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "We’re the trusted lawn care specialists that 
                      help you own an impressive yard without lifting a finger" <br></br>
                      <br></br>
                      <small>-Miguel</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("./../../assets/img/landscaping/lanscaping4.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("./../../assets/img/landscaping/lanscaping3.jpg") + ")",
                    }}
                  ></div>
                  <h3>
                    ENHANCE THE VALUE OF YOUR LANDSCAPE
                  </h3>
                  <p>
                    We provide comprehensive landscaping, grounds management, 
                    and tree care services on private and comercial properties 
                    across Michigan. Our Team maintains safe and beautiful 
                    landscapes on a variety of property types.
                  </p>
                  <p>
                    Miguel landscaping team can provide a number of services, 
                    including landscape maintenance, snow removal and storm 
                    response, irrigation installation and management, and more.
                  </p>
                  <p>
                    Discover the ways Miguel commercial landscaping services 
                    can add to the value of your commercial property.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Request a Consultation</h2>
            <p className="description">Your project is very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
